import * as storage from "../src/storage";

let prepareData = async function( metrics, callType = "t" ) {

	const _formatListVars = ( listvar, index ) => {

			// If listvar is not defined or is an empty string, return an empty array.
			if ( typeof ( listvar ) === 'undefined' || listvar === null || listvar === "" ) return [];

			// If listvar is already an array, return it.
			if ( Array.isArray( listvar ) ) return { value: listvar.join() };

			// If listvar is a string, split it by the appropriate delimiter and return an array of objects.
			let delim = ( index === 2 ? "|" : "," );
			return listvar.split( delim ).map( ( e ) => ( { value: e } ) )

		},
		_pageName = metrics.pageName ? metrics.pageName : metrics.pageURL;		
			
	console.log( LOG_PREFIX + " -- metrics: ", JSON.parse( JSON.stringify( metrics ) ) );
	// console.log( LOG_PREFIX + " -- metrics.linkTrackVars: ", JSON.parse( JSON.stringify( metrics.linkTrackVars ) ) );
	// console.log( LOG_PREFIX + " -- metrics.linkTrackEvents: ", JSON.parse( JSON.stringify( metrics.linkTrackEvents ) ) );

	let xdmData = {
		"_experience": {
			"analytics": {
				"customDimensions": {
					"props": {},
					"eVars": {}
				}
			}
		},
		"web": {
			"webPageDetails": {
				"name": _pageName,
				"URL": metrics.pageURL,
				"siteSection": metrics.channel ?? ""
			},
			"webReferrer": {
				"URL": ( metrics.referrer ) ? metrics.referrer : document.referrer
			}
		},
		"marketing": {
			"trackingCode": metrics.campaign ?? ""
		}
	}

	if ( metrics.products ) {
		xdmData.productListItems = metrics.products;
	}

	if ( metrics.state || metrics.zip ) {
		
		xdmData.placeContext = {
			geo: {
				"postalCode": metrics.zip,
				"stateProvince": metrics.state
			}
		};

	}

	if ( metrics.purchaseID || metrics.transactionID ) {
		
		xdmData.commerce = xdmData.commerce ?? {};
		xdmData.commerce.order = xdmData.commerce.order ?? {};
		
		if ( metrics.purchaseID ) { xdmData.commerce.order.purchaseID = metrics.purchaseID; }
		if ( metrics.transactionID ) { 
			xdmData.commerce.order.payments = xdmData.commerce.order.payments ?? [];
			xdmData.commerce.order.payments[ 0 ] = {
				transactionID: metrics.transactionID
			}
		}

	}

	// ListVar
	xdmData._experience.analytics.customDimensions.lists = {};

	for ( let index = 1; index <= 3; index += 1 ) {

		if ( metrics[ 'list' + index ] ) {
			xdmData._experience.analytics.customDimensions.lists[ 'list' + index ] = {};
			xdmData._experience.analytics.customDimensions.lists[ 'list' + index ].list = _formatListVars( metrics[ 'list' + index ], index );
		}

	}

	// set FINAL value of evar17/prop35 [TODO: revisit post V7 launch]
	metrics.eVar17 = _pageName;
	
	if ( typeof ( metrics.prop35 ) !== 'undefined' && metrics.prop35 !== "" ) {

		metrics.prop35 = _pageName;

	}
	
	Object.entries( metrics ).forEach( ( [ key, value ] ) => {

		if ( value && key.includes( "prop" ) && ( callType === "t" ? true : metrics.linkTrackVars.has( key ) ) ) {
			xdmData._experience.analytics.customDimensions.props[ key ] = value;
		} else if ( value && key.includes( "eVar" ) && ( callType === "t" ? true : metrics.linkTrackVars.has( key ) ) ) {
			xdmData._experience.analytics.customDimensions.eVars[ key ] = value;
		} else if ( value && key.includes( "events" ) ) {

			// console.log( LOG_PREFIX + " -- events: ", value );

			let list = value.split( "," );

			// console.log( LOG_PREFIX + " -- list: ", list );

			list.forEach( event => {
				// console.log( LOG_PREFIX + " -- event: ", event );

				let keyAndValue = event.split( "=" );

				let key = keyAndValue[ 0 ];

				callType !== "t" && console.log( LOG_PREFIX + " -- metrics.linkTrackEvents has " + event + " ", metrics.linkTrackEvents.has( event ) );

				if ( callType === "t" ? true : metrics.linkTrackEvents.has( key ) ) {

					if ( keyAndValue[ 0 ].indexOf( "event" ) > -1 ) {

						let eventKey = keyAndValue[ 0 ].slice( 5 );
						let eventValue = keyAndValue[ 1 ] ? keyAndValue[ 1 ] : 1;

						// console.log( LOG_PREFIX + " Custom Event -- keyAndValue: " + keyAndValue[ 0 ].slice(5) + " = " + keyAndValue[ 1 ] );

						if ( eventKey < 101 ) {
							xdmData._experience.analytics.event1to100 = xdmData._experience.analytics.event1to100 ?? ( xdmData._experience.analytics.event1to100 = {} );
							xdmData._experience.analytics.event1to100[ 'event' + eventKey ] = { 'value': eventValue };
						} else if ( eventKey > 100 && eventKey < 201 ) {
							xdmData._experience.analytics.event101to200 = xdmData._experience.analytics.event101to200 ?? ( xdmData._experience.analytics.event101to200 = {} );
							xdmData._experience.analytics.event101to200[ 'event' + eventKey ] = { 'value': eventValue };
						} else if ( eventKey > 200 && eventKey < 301 ) {
							xdmData._experience.analytics.event201to300 = xdmData._experience.analytics.event201to300 ?? ( xdmData._experience.analytics.event201to300 = {} );
							xdmData._experience.analytics.event201to300[ 'event' + eventKey ] = { 'value': eventValue };
						} else if ( eventKey > 300 && eventKey < 401 ) {
							xdmData._experience.analytics.event301to400 = xdmData._experience.analytics.event301to400 ?? ( xdmData._experience.analytics.event301to400 = {} );
							xdmData._experience.analytics.event301to400[ 'event' + eventKey ] = { 'value': eventValue };
						} else if ( eventKey > 400 && eventKey < 501 ) {
							xdmData._experience.analytics.event401to500 = xdmData._experience.analytics.event401to500 ?? ( xdmData._experience.analytics.event401to500 = {} );
							xdmData._experience.analytics.event401to500[ 'event' + eventKey ] = { 'value': eventValue };
						} else if ( eventKey > 500 && eventKey < 601 ) {
							xdmData._experience.analytics.event501to600 = xdmData._experience.analytics.event501to600 ?? ( xdmData._experience.analytics.event501to600 = {} );
							xdmData._experience.analytics.event501to600[ 'event' + eventKey ] = { 'value': eventValue };
						} else if ( eventKey > 600 && eventKey < 701 ) {
							xdmData._experience.analytics.event601to700 = xdmData._experience.analytics.event601to700 ?? ( xdmData._experience.analytics.event601to700 = {} );
							xdmData._experience.analytics.event601to700[ 'event' + eventKey ] = { 'value': eventValue };
						} else if ( eventKey > 700 && eventKey < 801 ) {
							xdmData._experience.analytics.event701to800 = xdmData._experience.analytics.event701to800 ?? ( xdmData._experience.analytics.event701to800 = {} );
							xdmData._experience.analytics.event701to800[ 'event' + eventKey ] = { 'value': eventValue };
						} else if ( eventKey > 800 && eventKey < 901 ) {
							xdmData._experience.analytics.event801to900 = xdmData._experience.analytics.event801to900 ?? ( xdmData._experience.analytics.event801to900 = {} );
							xdmData._experience.analytics.event801to900[ 'event' + eventKey ] = { 'value': eventValue };
						} else if ( eventKey > 900 && eventKey < 1001 ) {
							xdmData._experience.analytics.event901to1000 = xdmData._experience.analytics.event901to1000 ?? ( xdmData._experience.analytics.event901to1000 = {} );
							xdmData._experience.analytics.event901to1000[ 'event' + eventKey ] = { 'value': eventValue };
						}

					}

					switch ( keyAndValue[ 0 ] ) {
						case "scAdd":
							xdmData.commerce = xdmData.commerce ?? {};
							xdmData.commerce.productListAdds = { "value": 1 };
							break;
						case "scRemove":
							xdmData.commerce = xdmData.commerce ?? {};
							xdmData.commerce.productListRemovals = { "value": 1 };
							break;
						case "scCheckout":
							xdmData.commerce = xdmData.commerce ?? {};
							xdmData.commerce.checkouts = { "value": 1 };
							break;
						case "scView":
							xdmData.commerce = xdmData.commerce ?? {};
							xdmData.commerce.productListViews = { "value": 1 };
							break;
						case "scOpen":
							xdmData.commerce = xdmData.commerce ?? {};
							xdmData.commerce.productListOpens = { "value": 1 };
							break;
						case "purchase":
							xdmData.commerce = xdmData.commerce ?? {};
							xdmData.commerce.purchases = { "value": 1 };
							break;
						case "prodView":
							xdmData.commerce = xdmData.commerce ?? {};
							xdmData.commerce.productViews = { "value": 1 };
							break;
						default:
							// Handle other cases if needed
							break;
					}

				}

			} )

		}

	} );

	console.log( LOG_PREFIX + " -- ECID: " + _aape.ECID );

	/* eVar captures visitID to aid filter of visitors identified as bots (supports perimeterx integration) */
	xdmData._experience.analytics.customDimensions.eVars.eVar99 = _aape.ECID;

	try {

		let hhID = storage.getItem( "hhidFromSVOCID" );
		let hhIDCookieExists = !!hhID;
		// let hhidTargetOfferCalled = storage.getItem( "hhidTargetOfferCalled" );

		xdmData.identityMap = ( function() {

			let customerIDs = {},
				mcid = _aape.ECID,
				svocidsync = ( function getSVOCID( ddo ) {
					let result = {
						"svocid": "",
						"authstatus": ""
					},
						authstatus = "";

					ddo = typeof ddo !== 'undefined' ? ddo : {};

					if ( ddo.user && ddo.user.profile && ddo.user.profile.profileInfo && ddo.user.profile.profileInfo.profileID ) {

						result.svocid = ddo.user.profile.profileInfo.profileID.toString().toLowerCase();

						authstatus = ( ddo.user.profile.authenticationStatus ? ddo.user.profile.authenticationStatus.toString().toLowerCase() : '' );
						result.authstatus = ( authstatus.indexOf( "authenticated" ) ? "authenticated" : "ambiguous" );
					}

					return result;
				}( window.digitalData ) );

			// Add SVOCID SYNC information to customer id list if its available
			if ( svocidsync.svocid ) {

				customerIDs.svocid = [ {
					"id": svocidsync.svocid,
					"authenticatedState": svocidsync.authstatus
				} ];

				customerIDs.b2b = [ {
					"id": svocidsync.svocid,
					"authenticatedState": svocidsync.authstatus
				} ];

			}

			if ( xdmData._experience.analytics.customDimensions.eVars.eVar46 ) {
				customerIDs.H0med3p0t1 = [ {
					"id": xdmData._experience.analytics.customDimensions.eVars.eVar46,
					"authenticatedState": "authenticated"
				} ];
			}

			if ( xdmData._experience.analytics.customDimensions.eVars.eVar94 ) {
				customerIDs.H0med3p0t2 = [ {
					"id": xdmData._experience.analytics.customDimensions.eVars.eVar94.toString().toLowerCase(),
					"authenticatedState": "authenticated"
				} ];
			}

			if ( xdmData._experience.analytics.customDimensions.eVars.eVar152 ) {
				customerIDs.H0med3p0t3 = [ {
					"id": xdmData._experience.analytics.customDimensions.eVars.eVar152.toString().toLowerCase(),
					"authenticatedState": "authenticated"
				} ];
			}

			if ( hhID ) {

				xdmData._experience.analytics.customDimensions.eVars.eVar183 = hhID;

				customerIDs.H0med3p0t4 = [ {
					"id": hhID,
					"authenticatedState": "authenticated"
				} ];
				customerIDs.H0med3p0t5 = [ {
					"id": hhID,
					"authenticatedState": "authenticated"
				} ];

			} else {

				xdmData._experience.analytics.customDimensions.eVars.eVar183 = ( hhIDCookieExists ? "not available" : "no cookie" );

			}

			//ECR PARTY ID
			if ( xdmData._experience.analytics.customDimensions.eVars.eVar249 ) {
				customerIDs.H0med3p0t6 = [ {
					"id": xdmData._experience.analytics.customDimensions.eVars.eVar249.toString().toUpperCase(),
					"authenticatedState": "authenticated"
				} ];
			}

			if ( mcid ) {
				customerIDs.bmcvid = [ {
					"id": mcid
				} ];
			}
			
			console.log(LOG_PREFIX + " -- customerIDs: ", customerIDs);

			return customerIDs;

		}() );

		// TODO: See if we still need this
		// if ( !hhidTargetOfferCalled && hhID && window.adobe && adobe.target && typeof adobe.target.getOffer === "function" ) {
		// 	adobe.target.getOffer( {
		// 		"mbox": "sync-identifiers",
		// 		"success": function( offer ) {

		// 			adobe.target.applyOffer( {
		// 				"mbox": "sync-identifiers",
		// 				"offer": offer
		// 			} );

		// 			storage.setItem( "hhidTargetOfferCalled", true );

		// 		},
		// 		"error": function( status, error ) {
		// 			console.log( LOG_PREFIX + " -- Error", status, error );
		// 		}
		// 	} );
		// }

	}

	/* eslint no-empty: ["error", { "allowEmptyCatch": true }] */
	catch ( error ) {
		console.error( LOG_PREFIX + " -- ID Syncs Failed -- error: ", error );
	}

	console.log( LOG_PREFIX + " -- xdmData: ", xdmData );

	return xdmData;

};

export {
	prepareData
}